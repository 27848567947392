/* Shared request method */
const request = async (url: string, method = 'GET', data: object = null, headers = {}): Promise<any> => {
    const config = { method, headers: new Headers(headers) };
    if (data) config['body'] = JSON.stringify(data);

    return fetch(url, config);
};

const requestJson = async (url: string, method = 'GET', data: object = null, headers = {}) => {
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';

    const response = await request(url, method, data, headers);
    return response;
};

/* Celli resources */
const CELLI_DOMAIN = process.env.NODE_ENV === 'production' ? 'https://celli.cuenca.io' : 'http://localhost:5000';

const sendCelliLink = async (phone_number: string, utm_source?: string, token?: string) => {
    const payload = {
        phone_number,
        utm_source,
        token,
    };

    let response = {
        ok: false,
        message: 'Mensaje no enviado, intente de nuevo',
    };

    try {
        response = await requestJson(`${CELLI_DOMAIN}/invites`, 'POST', payload);
    } catch (error) {
        return { error, success: false };
    }

    if (response.ok) return { response, success: true };

    return { error: response.message, success: false };
};

export const celli = { sendCelliLink };

/* Facebook Pixel resources */
const fb_track = (eventName, window) => {
    try {
        if (typeof window.fbq === 'function') {
            window.fbq('track', eventName, {});
        }
    } catch (error) {
        setTimeout(function () {
            fb_track(eventName, window);
        }, 3000);
    }
};

/* TikTok */
const ttq_track = (eventName, window) => {
    try {
        if (typeof window.ttq === 'object') {
            window.ttq.track(eventName);
        }
    } catch (error) {
        setTimeout(function () {
            ttq_track(eventName, window);
        }, 3000);
    }
};

export const pixel = { fb_track, ttq_track };
